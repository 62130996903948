var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    intersection: {
      rootMargin: '-35% 0px -35% 0px',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    intersection: {\n      rootMargin: '-35% 0px -35% 0px',\n    },\n  }"}],class:[_vm.option.id, 'relative', 'animation']},[_c('div',{staticClass:"wrap relative"},[_c('div',{staticClass:"contents flex flex-wrap lg:justify-between lg:items-start"},[_c('div',{staticClass:"m-auto lg:m-0"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.option.mainImage[0]),expression:"option.mainImage[0]"}],staticClass:"inline lg:hidden"}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.option.mainImage[1]),expression:"option.mainImage[1]"}],staticClass:"hidden lg:inline"})]),_c('div',{},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.option.titleText[0]),expression:"option.titleText[0]"}],staticClass:"inline lg:hidden",attrs:{"alt":_vm.option.titleText[2]}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.option.titleText[1]),expression:"option.titleText[1]"}],staticClass:"hidden lg:inline",attrs:{"alt":_vm.option.titleText[2]}}),_c('p',{staticClass:"hidden lg:block",domProps:{"innerHTML":_vm._s(_vm.option.descriptionText[0])}}),_c('p',{staticClass:"block lg:hidden",domProps:{"innerHTML":_vm._s(_vm.option.descriptionText[1])}})]),_c('a',{staticClass:"ml-auto block btn lg:ml-0 lg:mr-auto",attrs:{"href":_vm.option.link
              ? _vm.option.link
              : 'https://twitter.com/mokuhoikuen_art?s=20',"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.option.btn[0]),expression:"option.btn[0]"}],staticClass:"inline lg:hidden"}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.option.btn[1]),expression:"option.btn[1]"}],staticClass:"hidden lg:inline"})]),(_vm.option.id == 'photographer' || _vm.option.id == 'creater')?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            _vm.option.id == 'photographer'
              ? '/images/pc/p_1.png'
              : '/images/pc/c_1.jpg'
          ),expression:"\n            option.id == 'photographer'\n              ? '/images/pc/p_1.png'\n              : '/images/pc/c_1.jpg'\n          "}],staticClass:"hidden lg:inline"}):_vm._e()])]),(_vm.option.id == 'artist')?_c('div',[_c('div',{staticClass:"circle"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/pc/a_1.png'),expression:"'/images/pc/a_1.png'"}],staticClass:"hidden lg:inline"})]),_c('div',{staticClass:"bg hidden lg:block"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/bg/bg_2.png'),expression:"'/images/bg/bg_2.png'"}],staticClass:"bg_2"}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/bg/bg_art_1.png'),expression:"'/images/bg/bg_art_1.png'"}],staticClass:"bg_3 absolute"})])]):_vm._e(),(_vm.option.id == 'photographer')?_c('div',[_c('div',{staticClass:"bg hidden lg:block"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/bg/bg_3.png'),expression:"'/images/bg/bg_3.png'"}],staticClass:"bg_1 absolute"}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/bg/bg_4.png'),expression:"'/images/bg/bg_4.png'"}],staticClass:"bg_2 absolute"})])]):_vm._e(),(_vm.option.id == 'creater')?_c('div',[_c('div',{staticClass:"bg hidden lg:block"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/bg/bg_1.png'),expression:"'/images/bg/bg_1.png'"}],staticClass:"bg_1 absolute "})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }