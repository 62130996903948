














































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Photographer extends Vue {
  @Prop()
  option!: { [key: string]: [] | string };
  visibilityChanged(isVisible: boolean, entry: any) {
    if (isVisible) {
      entry.target.classList.add("fadeIn");
    }
  }
}
